.userProfile-Page,
.companyInfo-Page {
  background: var(--main-bg);
  box-shadow: var(--box-shadow);
  padding: 30px;
  border-radius: var(--border-radius);
}
span.info__button {
  position: absolute;
  z-index: 9;
  right: 25px;
  top: 10px;
  font-size: 20px;
  color: var(--txt-color);
}
.custom__label .info__button {
  right: -26px;
  top: -1px;
}
.custom__label {
  position: relative;
  display: inline-block;
}
.company__thumb-here {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px dashed;
  border-color: var(--txt-color);
  border-radius: var(--border-radius);
  margin: 22px auto;
}
.tq-form .browser__Btn {
  text-align: center;
}
.ag-theme-balham .table__notification .ag-header {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.bucket__card {
  padding: 16px 20px !important;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  margin-bottom: 0;
}
.bucket__card .status-card__info {
  flex: 0 0 100%;
  margin: 10px 0;
}
.bucket__card .status-card__info span {
  font-size: 15px;
  font-weight: 600;
}
.bucket__card .status-card__info h4 {
  font-size: 20px;
  margin: 0;
}
.bucket__card i {
  font-size: 32px;
  z-index: 2;
}
.bucket__status-true {
  color: var(--main-color-green);
}

.bucket__status-false {
  color: var(--main-color-red);
}
/* ------------- Create Bucket ------------ */
.btn-check + .btn-userGroup {
  background-color: var(--gray-300);
  border-color: var(--gray-300);
  font-weight: 500;
  padding: 8px 18px;
}
/* .btn-check:checked:hover + .btn-userGroup, */
.btn-check:checked + .btn-userGroup {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: var(--main-bg);
}
.bucket__status-wrap .btn-check:checked + .btn-userGroup {
  background-color: var(--main-color-green);
  border-color: var(--main-color-green);
  color: var(--main-bg);
}

.btn-check:hover + .btn-userGroup {
  background-color: var(--second-color-orange);
}
.btn-check:checked + .btn-userGroup.inactive {
  background-color: var(--main-color-red);
  border-color: var(--main-color-red);
}
.border__top {
  border-top: 3px dashed #e2e2e2;
}
.add__user-wrapper {
  padding: 20px;
  background: #d7d7d7;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-bottom: 50px;
}
.add__user-wrapper .instruction {
  font-size: 20px;
  font-weight: 600;
}


#tbg-false:checked + .btn-userGroup {
  background-color: var(--main-color-red) !important;
  border-color: var(--main-color-red);
}

#tbg-true:checked + .btn-userGroup {
  background-color: rgb(0, 187, 72);
  border-color: rgb(0, 187, 72);
}