.jd__accordion .accordion-item,
.schedule__interview-wrapper .accordion-item,
.schedule__interview-wrapper
  .accordion-item:last-of-type
  .accordion-button.collapsed {
  border-radius: var(--border-radius);
  border: none;
}
.jd__accordion .accordion-button:focus {
  border-color: unset;
  box-shadow: unset;
}
.jd__accordion .accordion-button {
  padding: 8px 18px;
  background-color: transparent;
}
.jd__accordion .accordion-button:not(.collapsed) {
  color: unset;
  background-color: unset;
  box-shadow: unset;
}
.jd__accordion .accordion-item .accordion-header,
.schedule__interview-wrapper .accordion-item .accordion-header {
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: var(--card-footer);
  overflow: hidden;
}
.schedule__interview-wrapper .accordion-item .accordion-button:focus {
  z-index: 3;
  border-color: #fede86;
  box-shadow: 0 0 0 0.25rem rgba(253, 229, 13, 0.25);
}
.schedule__interview-wrapper .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  /* background-color: var(--main-color); */
  background-color: var(--txt-color);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.create__slot-interview .accordion-item .accordion-button:not(.collapsed) {
  color: var(--txt-color) !important;
}
.schedule__interview-wrapper
  .accordion-item
  .accordion-button:not(.collapsed)::after {
  filter: grayscale(1) invert(41);
}
.interview__round-body {
  position: relative;
}
.interview__round-body .add__interview-round {
  position: absolute;
  top: 28px;
  right: 30px;
  background: var(--main-color-blue) !important;
  padding: 8px 14px;
  border-radius: 10px;
  font-size: 14px;
}
.interview__round-body .schadule__content {
  border-radius: var(--border-radius);
  padding: 14px;
  height: 38rem;
  overflow-y: scroll;
}
.schedule__interview-wrapper.create__slot-interview .accordion-header button {
  background: #dee2e6 !important;
  font-weight: 500;
}
.interview__round-body .candidate__tab-wrap .nav-link.active {
  margin: 0;
  border-radius: 0;
}
.interview__round-body .candidate__tab-wrap {
  background-color: var(--second-bg);
  padding: 10px;
}
.interview__round-body .add__interview-round .filter__btn {
  color: var(--main-bg);
  background-color: transparent !important;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slots__list-wrapper {
  border-bottom: 14px solid #fff;
  margin-bottom: 21px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  margin-bottom: 50px;
  box-shadow: var(--box-shadow);
}
.slots__list-wrapper .datesAvl {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  background: var(--bs-gray-600);
  color: #fff;
  padding: 6px;
}
.slots__list-wrapper .slots__listing {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0;
  padding: 16px;
}
.slots__list-wrapper .available__slots-card {
  display: flex;
  background: #cbe2e3;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  margin-right: 20px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
.slots__list-wrapper .available__slot-row .slot__booked-for {
  text-align: center;
  padding: 5px 2px;
  background: var(--main-color);
}
.slots__list-wrapper .available__slot-row {
  padding: 10px 8px;
  font-weight: 600;
  color: var(--txt-color);
}
.slots__list-wrapper span.availability.reset {
  background: rgb(255, 102, 102) !important;
}
.slots__list-wrapper span.availability.true {
  background: var(--main-color-green);
  cursor: pointer;
}
.slots__list-wrapper span.availability.false {
  background: var(--main-color-red);
}
.slots__list-wrapper span.availability {
  display: block;
  text-align: center;
  color: var(--txt-white);
  font-weight: 600;
  font-size: 12px;
  padding: 5px 0;
}
.bookingLimit {
  font-size: 20px;
}
.refresh__candidate,
.refresh__rounds {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.refresh__rounds h6 {
  margin-right: 10px;
  color: #003172;
}
.refresh__candidate {
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}
