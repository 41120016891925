.profile__card,
.admin__user-card {
    padding: 20px;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 14px;
    /* cursor: pointer; */
    height: 100%;
}
.alpha__filter {
    display: flex;
    background: rgb(56, 56, 56);
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
}
.profile__card .card__header,
.user__card-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.user__card-header .profile__image {
    max-width: 90px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;
}
.header__details .title {
    font-size: 20px;
    text-transform: capitalize;
}
.header__details
.company__type {
    display: block;
    font-size: 15px;
    color: var(--txt-color);
    opacity: 0.7;
}
.header__details .Active
{
    color: rgb(0, 185, 0);
    font-weight: 500;
    background: unset;
}
.header__details .Inactive
{
    color: rgb(216, 0, 0);
    font-weight: 500;
    background: unset;
}
.user__card-content .text-row
{
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin-bottom: 5px;
    font-family: var(--poppins);
}

.user__card-content .text-row strong
{
    flex: 0 0 40%;
}
.user__card-content .text-row span
{
    flex: 0 0 60%;
    word-break: break-word;
    line-height: 1.2;
}
/* ------------------ */
.profile__card .card__header
{
    margin-bottom: 34px;
}
.profile__card 
.card__body 
{
    border-top: 2px solid #e2e2e2;
    padding-top: 34px;
}
.tq-cards .text-row,
.profile__card .card__body .text-row 
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    font-size: 16px;
    color: #333;
    font-family: var(--poppins);

}

.legal__cards .text-row strong,
.profile__card 
.card__body .text-row strong
{
    color: var(--txt-color);
    opacity: 0.9;
    font-weight: 600;
}
.profile__card .header__details .title {
    font-size: 28px;
    color: var(--txt-color);
}
.profile__card .profile__image {
    max-width: 140px;
    margin-right: 30px;
    overflow: hidden;
    border-radius: 14px;
}

.header__details
.company__type
{
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 500;
    opacity: 0.9;
}
.header__details .location i
{
    font-size: 20px;
}
.tq-cards{
    box-shadow: var(--box-shadow);
    padding: 20px;
    background-color: #fff;
    border-radius: 14px;
}
.tq-cards .card__heading .title,
.legal__cards .card__heading .title
{
    font-size: 22px;
    font-weight: 700;
}.card__heading .edit__details {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e7a000;
    font-size: 22px;
    cursor: pointer;
}
.card__heading .edit__details:hover
{
    color: var(--txt-color);
}
.tq-cards .card__heading, 
.contract__details .card__heading, 
.legal__cards .card__heading {
    align-items: center;
    font-size: 20px;
    margin-bottom: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
}
.contract__details .content__details p
{
    font-size: 20px;
    font-weight: 600;
    font-family: var(--poppins);
}

/* ----------------- */
.user__card-admin .arrival__time, 
.job__Admin-card .arrival__time {
    text-transform: capitalize;
    font-family: var(--poppins);
    font-size: 12px;
    opacity: 0.8;
    position: absolute;
    right: calc(40px + 16px);
    top: 10px;
}
.tq-cards .actions,
.job__Admin-card .actions {
    border-top: 1px dashed #a1a1a1;
    padding-top: 14px;
    margin-top: 24px;
}
.user__card-admin,
.job__Admin-card 
{
    overflow: hidden;
}
.job__Admin-card .company__status mark
{
    background-color: #fff8da;
    padding: 0.2em 0.4em;
}
.user__card-admin .status i,
.job__Admin-card .status i
{
    font-size: 18px;
}
.user__card-admin .status,
.job__Admin-card .status {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    top: -2px;
    right: 0px;
    border-radius: 0 0 0 20px;
}
.job__Admin-card .status.jod {
    right: unset;
    left: 0;
    border-radius: 0 0 20px 0;
    background-color: var(--main-color);
}
.tq-cards .text-row strong,
.job__Admin-card.tq-cards .text-row strong
{
    font-weight: 600;
}
.tq-cards .text-row strong,
.job__Admin-card.tq-cards .text-row strong,
.tq-cards .text-row span,
.job__Admin-card.tq-cards .text-row span
{
    font-family: var(--poppins);
    font-size: 14px;
    opacity: 0.7;
}
.multipl__upload .custom-file-input {
    color: rgba(51, 51, 51, 0.8);
    font-size: 14px;
    padding-right: 18px !important;
  }
  .multipl__upload .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .multipl__upload .custom-file-input::before {
    content: 'Select files';
    color: rgba(51, 51, 51, 0.8);
    display: inline-block;
    background: -webkit-linear-gradient(top, #ffd311, #ffc400);
    /* border: 1px solid #999; */
    border-radius: 3px;
    padding: 14px 18px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .multipl__upload .custom-file-input:hover::before {
    border-color: black;
  }
  .multipl__upload .custom-file-input:active {
    outline: 0;
  }
  .multipl__upload .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}
.admin
{
  display: inline-block;
  padding: 1px 8px;
  opacity: 1 !important;
}
  .adgreen
  {
    background-color: #04913b;
    color: #fff;
  }
  .pcomplete
  {
    background-color: #00dee6;
    color: #333;
  }
  .dred
  {
    background-color: #c70202;
    color: #fff;
  }
  .Verified
  {
    background-color: #0094d8;
    color: #fff;
  }
  .Registered
  {
    background-color: #f1ed00;
    color: #333;
  }
  .inactive
  {
    background-color: #ff9100;
    color: #333;
  }
  .search-btn {
    padding: 10px 30px;
    font-size: 16px;
    color: #333;
}