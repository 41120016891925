.checkmark {
  position: relative;
}
.forgot-text.changeUID {
  position: absolute;
  right: 30px;
  bottom: 12px;
  font-weight: bold;
}
.userTypeCorpStyle {
  border: 1px solid grey;
  padding: 10px 25px;
}
.userTypeVendorStyle {
  border: 1px solid grey;
  padding: 10px 17px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.forgot-text {
  display: flex;
  cursor: pointer;
  align-items: end;
  color: var(--tq-theme) !important;
  justify-content: end;
}
.tq-form-container .MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--tq-theme) !important;
}
.text-button-tq {
  cursor: pointer;
  color: #ffd700 !important;
  font-weight: bold;
}
.page-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.form-v4-content {
  background: #fff;
  width: 830px;
  border-radius: 10px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  margin: 33px 0;
  position: relative;
  display: flex;
  font-family: "Open Sans", sans-serif;
}
.form-v4-content .signup {
  font-size: 20px;
  font-family: "Tajawal", sans-serif;
  color: black;
  font-weight: bold;
}
.form-v4-content .loginNow {
  color: #ffd700;
  font-weight: bold;
  font-size: 12px;
  pointer-events: auto;
}
.form-v4-content .haveAccount {
  font-size: 12px;
  margin-right: 5px;
}

.form-v4-content .form-left {
  background: #fbf2c6;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 40px;
  position: relative;
  width: 50%;
  color: #505050;
}
.form-v4-content .form-left p {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.5;
}

.form-v4-content .form-left .text-2 {
  margin: 65px 0 15px;
}

.form-v4-content .form-detail {
  /* padding: 20px 40px; */
  position: relative;
  width: 50%;
}
/* .form-v4-content .form-detail h2 {
  color: #3786bd;
} */
.form-v4-content .form-detail label {
  font-weight: 600;
  font-size: 10px;
  color: #666;
  display: block;
  margin-bottom: 12px;
}

.form-v4-content .form-detail .boxinput {
  width: 100%;
  padding: 11.5px 15px;
  border: 1px solid #e5e5e5;
  /* appearance: unset; */
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #333;
  box-sizing: border-box;
}
.form-v4-content .form-detail .boxinput:focus {
  border: 1px solid #2f73a3;
}
.form-v4-content .form-detail .form-checkbox {
  margin-top: 1px;
  position: relative;
}

/* Responsive */
@media screen and (max-width: 991px) {
  .form-v4-content {
    margin: 180px 20px;
    flex-direction: column;
  }
  .form-v4-content .form-left {
    width: auto;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
  .form-v4-content .form-detail {
    padding: 30px 20px 30px 20px;
    width: auto;
  }
}
@media screen and (max-width: 575px) {
  .form-v4-content .form-detail .boxinput {
    flex-direction: column;
    margin: 0;
  }
  .form-v4-content .form-detail .boxinput {
    width: 100%;
    padding: 10px;
  }
}
.backgroundandheight {
  background-color: #f6f3f0;
  height: 100vh;
}

.boxinput {
  width: 100%;
  padding: 10px 10px;
  margin: 2px 0 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
/* .container {
  margin: 20px;
} */
.div {
  width: 310px;
  margin: auto;
}
.forgpas {
  font-size: 10px;
  float: right;
  color: gray;
  cursor: pointer;
}
.remindme {
  font-size: 10px;
  display: inline;
  margin-top: 1px;
}
.headingall {
  color: #ff7f00;
  font-size: 12px;
  padding: 0;
  margin: 10px 10px 10px 0px;
}

.actionContainer {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
/* .personalInfoContainer {
  padding: 20px 40px;
} */
.validatebtn {
  background-color: #4caf50;
  color: white;
  width: 100%;
  padding: 7px;
  border: none;
  border-radius: 3px;
  margin: 30px 7px 0 0;
  cursor: pointer;
}
.basicPlan {
  border: 1px solid grey;
  text-align: center;
  padding: 5px;
}

/* choose plan type */
.chooseplan {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-bottom: 12px;
}
.freeplan {
  width: 50%;
  border: 1px solid #ffe6e6;
  border-radius: 5px;
  text-align: center;
  margin-right: 5px;
}
.backgroundfree {
  background-color: orange;
  border-radius: 5px;
  padding: 3px;
  color: white;
}
.freeplan .name {
  font-size: 15px;
  margin: 0px;
}
.freeplan .cost {
  font-size: 12px;
  margin: 2px 0px 0px 0px;
}
.freeplan .description {
  font-size: 12px;
  padding: 8px;
}
.premiumplan {
  width: 50%;
  border: 1px solid #ffe6e6;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
}
.backgroundprim {
  background-color: #4000ff;
  border-radius: 5px;
  padding: 3px;
  color: white;
}
.premiumplan .name {
  font-size: 15px;
  margin: 0px;
}
.premiumplan .cost {
  font-size: 12px;
  margin: 2px 0px 0px 0px;
}
.premiumplan .description {
  font-size: 12px;
  padding: 8px;
}
input[type="checkbox"] {
  margin: 4px;
}
.passpattern .passcontent {
  font-size: 10px;
  color: red;
  margin: 0px;
}
.disableContainer {
  pointer-events: none;
  background-color: #dddddd;
  opacity: 0.5;
}
@media screen and (max-width: 520px) {
  .backgroundandheight {
    background-color: #f6f3f0;
    width: 830px;
    height: 220vh;
    justify-content: center;
    align-items: center;
  }
}

/* -------- New UI Styles ------------ */
.login-section {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .register-section {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.tq-form-container [data-shrink="true"] {
  background: #fff;
  padding: 2px 14px;
  transform: translate(14px, -12px) scale(0.75) !important;
}
/* .login-card form, */
.form-detail,
.chooseUserType {
  padding: 10px 60px 60px;
}
.login-card .form-group {
  position: relative;
}
/* .login-card .floting-label {
  position: absolute;
  top: -16px;
  left: 12px;
  background: #fff;
  padding: 2px 14px;
  z-index: 1;
} */
/* .login-card .form-control,
.PhoneInputInput,
.PhoneInputCountrySelect {
  height: calc(1.5em + 0.75rem + 26px);
  padding: 20px 26px 16px;
  border-radius: 14px;
  border: 1px solid #ced4da;
  font-size: 16px;
}
.login-card .form-check-label,
.login-card .custom-control-label {
  text-align: left;
  padding: 4px;
  font-size: 14px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--tq-theme);
  background-color: var(--tq-theme);
} */
.form-check-label.forgpas {
  color: var(--tq-theme);
}
.custom-control-label::after,
.custom-control-label::before {
  width: 18px;
  height: 18px;
}
.tq-log-btn {
  padding: 14px 80px;
  border-radius: 10px !important;
  font-size: 15px;
  margin-top: 30px;
}
.login-card .tq-log-btn {
  padding: 14px 80px;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 30px;
}
.login-card .tq-log-btn:hover,
.login-card .tq-log-btn:focus {
  color: #fff;
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
  background-color: #999;
}
.login-card .read-more {
  cursor: pointer;
  color: var(--tq-theme);
  font-weight: 600;
}
.login-card .beforelogcheck {
  margin-top: 20px;
  font-size: 15px;
}
.tq-radio-card {
  padding: 30px;
  background: #e2e2e2;
  position: relative;
  border-radius: 20px;
  text-align: center;
  margin: 10px;
}
.user-type-one {
  background: #fff1ba;
}
.user-type-two {
  background: #bae2ff;
}
.tq-form-container form .MuiFormControl-root {
  width: 100%;
}
/* .registrationProcess .custom-control-label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -12px;
  background: #cac8c0;
  color: var(--tq-cl-33);
  padding: 4px 12px;
  border-radius: 5px;
  cursor: pointer;
} */

/* custom radio  */
.MuiIconButton-label > div {
  width: 25px;
  height: 25px;
  border: 3px solid #f2c40d;
  border-radius: 6px;
  position: relative;
}
.MuiIconButton-label > div > svg {
  opacity: 0;
}
.Mui-checked .MuiIconButton-label > div::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f2c40d;
}
.registrationProcess .custom-control-input:checked ~ .custom-control-label {
  color: #fff;
  border-color: var(--tq-theme);
  background: var(--tq-theme);
}
.registrationProcess .custom-control-label::after,
.registrationProcess .custom-control-label::before {
  display: none;
}
.Otp-verify div {
  justify-content: center;
}
/*---------- mobile number style ------*/
.PhoneInput {
  display: flex;
}
.PhoneInputCountry {
  position: relative;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  border-radius: 14px 0 0 14px;
  border: 1px solid #c4c4c4;
  padding: 18.5px 14px;
  height: 4rem;
  border-right: 0;
}
.PhoneInputCountry::before {
  position: absolute;
  content: "▾";
  font-size: 30px;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
}
.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
  width: 48px;
  position: absolute;
  height: 40px;
  z-index: 1;
  left: 50%;
  top: 58%;
  transform: translate(-50%, -50%);
}
.PhoneInputCountrySelect {
  padding: 20px 12px;
  max-width: 100%;
  border-right: 0;
  border-radius: 14px 0 0 14px;
  opacity: 0;
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.PhoneInputInput {
  flex: 0 0 75%;
  max-width: 75%;
  border-radius: 0 14px 14px 0;
  border: 1px solid #c4c4c4;
  padding: 18.5px 14px;
  height: 4rem;
  /* box-sizing: content-box; */
}
.PhoneInputInput:focus {
  color: var(--tq-cl-33);
  border-color: #ffd450;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.25);
}

/* miui arient changes  */
/* .tq-form-control .MuiOutlinedInput-root fieldset legend {
  width: calc(100% - 85%);
} */
.tq-form-control [data-shrink="true"] {
  padding: 0 10px;
  background-color: #fff;
}
.tq-form-control .MuiOutlinedInput-root {
  border-radius: 12px !important;
}
.tq-form-control {
  margin: 20px 0;
}
.tq-mt-0 {
  margin-top: 0 !important;
}
.tq-my-5 {
  margin: 5rem 0 !important;
}
.tq-my-4 {
  margin: 4rem 0 !important;
}
.tq-my-3 {
  margin: 3rem 0 !important;
}
.tq-my-2 {
  margin: 2rem 0 !important;
}
.tq-my-1 {
  margin: 1rem 0 !important;
}
.tq-mx-5 {
  margin: 0 5rem !important;
}
.tq-mx-4 {
  margin: 0 4rem !important;
}
.tq-mx-3 {
  margin: 0 3rem !important;
}
.tq-mx-2 {
  margin: 0 2rem !important;
}
.tq-mx-1 {
  margin: 0 1rem !important;
}
.tq-mt-5 {
  margin-top: 5rem !important;
}
.tq-mt-4 {
  margin-top: 4rem !important;
}
.tq-mt-3 {
  margin-top: 3rem !important;
}
.tq-mt-2 {
  margin-top: 2rem !important;
}
.tq-mt-1 {
  margin-top: 1rem !important;
}
.PhoneInputCountryIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.PhoneInputCountrySelect [value="ZZ"] {
  opacity: 0;
}
/*------------ New Login ----------*/
.position-relative {
  position: relative !important;
}
.tq-form-container .tq-form-heading {
  font-size: 40px;
  text-align: center;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-weight: 700 !important;
  width: 100%;
}
/* .tq-button {
  margin-top: 60px;
  background: rgb(240, 204, 0);
} */
.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
.tq-form-container {
  padding: 20px 40px 60px;
}
.otp__icon {
  font-size: 26px;
  vertical-align: middle;
  margin-right: 6px;
}
.intl-tel-input input,
.intl-tel-input input[type="text"],
.intl-tel-input input[type="tel"] {
  height: 3.5rem;
  width: 100%;
}
.intl-tel-input,
.tq-password-input .MuiFormControl-root {
  width: 100%;
}
.contact-float {
  position: absolute;
  left: 0;
  top: 4px;
  z-index: 9;
  background: #fff;
  padding: 4px 6px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  transform: translate(14px, -6px) scale(0.75);
}
.tq-form-container .tq-button {
  margin-top: 30px;
  padding: 10px 50px;
  background: #ffd700;
  border-radius: 10px;
  margin-bottom: 20px;
}
.tq-form-container .MuiOutlinedInput-root,
.contact-field input {
  border-radius: 14px;
}
.contact-field input {
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.text-center {
  text-align: center !important;
}
.error-message {
  text-align: left !important;
  color: red;
}
.btn-disabled-tq {
  background: rgba(179, 179, 179, 0.3) !important;
}
.w-100 {
  width: 100%;
}
.MuiFormGroup-root.tq-radio-group {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.tqDialogue .tq-form-container {
  padding: 80px 40px;
  text-align: center;
}
.tqDialogue [role="dialog"] {
  border-radius: 20px;
}
.dialogueMessageText {
  font-size: 14px;
  color: var(--tq-cl-33);
  opacity: 0.7;
  margin-top: 18px;
}
.dialogueHliteMsg {
  font-size: 32px;
  font-family: var(--tq-font);
  font-weight: var(--tq-fn-b);
}
.phoneWithCountry .react-tel-input {
  position: relative;
}
.phoneWithCountry .react-tel-input .special-label {
  font-size: 14px;
  position: absolute;
  top: -12px;
  z-index: 9;
  left: 12px;
  background: #fff;
  padding: 2px 6px;
  color: rgba(0, 0, 0, 0.4);
  display: none;
}
.phoneWithCountry .react-tel-input .form-control {
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  padding: 9px 14px;
  margin-top: 8px !important;
  padding-left: 52px;
  width: 100%;
}
.phoneWithCountry .react-tel-input .form-control:hover {
  /* border-color: #2e7d32; */
  border-color: #008145;
}
.phoneWithCountry .react-tel-input .form-control:focus {
  color: var(--tq-cl-33);
  /* border: 2px solid #2e7d32; */
  border: 2px solid #008145;
  border-radius: 12px;
  outline: 0;
  box-shadow: unset !important;
}
/* -------- captcha-wrapper ------------ */
.captcha-wrapper {
  display: flex;
  justify-content: center;
}
.tqlogModal {
  background: url(https://talenq.r.worldssl.net/talenq/img/login/logTexture.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
}

.register-fromWraper .login-texture {
  max-height: 280px;
}
.login-card,
.forgetCard,
.register-fromWraper {
  position: relative;
  max-width: 40%;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  background: #fff;
  margin-bottom: 50px;
}
.register__success-message {
  max-width: 40% !important;
  text-align: center;
}
.login-card .form-heading {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 32px;
}
.login-card::after,
.forgetCard::after,
.register-fromWraper::after {
  position: absolute;
  content: "";
  width: 400px;
  height: 90px;
  transform: rotate(30deg);
  bottom: -60px;
  left: -110px;
  background: var(--tq-theme);
}
.login-card::before,
.forgetCard::before,
.register-fromWraper::before {
  position: absolute;
  content: "";
  width: 400px;
  height: 90px;
  transform: rotate(-30deg);
  bottom: -60px;
  right: -110px;
  background: var(--tq-theme);
}
.steperHead {
  /* width: 40%; */
  margin: 2rem auto;
  padding: 50px 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 22px 22px 0 0;
  margin-bottom: 1px;
}
.steperHead .MuiStepIcon-root.MuiStepIcon-completed {
  color: #00b013;
}
.steperHead .MuiStepIcon-root.MuiStepIcon-active,
.steperHead .MuiStepLabel-label.MuiStepLabel-active {
  color: #f7d000 !important;
}

.steperHead .MuiStepLabel-alternativeLabel {
  font-size: 11px !important;
}
.register-fromWraper {
  border-radius: 0 0 22px 22px;
}
.forgetCard,
.register-fromWraper,
.steperHead {
  max-width: 50%;
}
