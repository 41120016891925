.click__indicator .box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.click__indicator {
  position: absolute;
  right: 50px;
  bottom: 55%;
  transform: translateY(-50%);
}
/* .click__indicator .box span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #ff8c00;
  border-right: 2px solid #00ffff;
  box-shadow: 4px 4px 4px -1px #fff;
  transform: rotate(45deg);
  margin: -20px;
  animation: animate 1.5s infinite;
} */
.click__indicator .box span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #ffd232;
  box-shadow: 4px 4px 4px -1px #fff;
  transform: rotate(45deg);
  margin: -20px;
  animation: animate 1.5s infinite;
}
.click__indicator .box span:nth-child(1) {
  animation-delay: -0.2s;
}
.click__indicator .box span:nth-child(2) {
  animation-delay: -0.4s;
}
.click__indicator .box span:nth-child(3) {
  animation-delay: -0.6s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
