.jd__title {
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--txt-color);
  margin-bottom: 2px;
  font-family: "Raleway", sans-serif;
}
.jd__postedon {
  font-size: 18px;
  font-weight: 500;
  color: var(--txt-color);
  font-family: "Raleway", sans-serif;
}
.jd__header {
  background: var(--gray-300);
  padding: 10px 20px;
  border-radius: var(--border-radius);
}
.Analytics-Page .form-label {
  color: var(--txt-color);
  font-size: 16px;
  font-weight: 600;
}
.Job__body {
  padding: 22px 14px;
}
.jd__data {
  display: flex;
  justify-content: space-between;
  align-items: top;
  text-align: left;
  padding: 4px 0;
  color: var(--txt-color);
  flex-wrap: wrap;
  text-transform: capitalize;
}
.jd__data strong {
  flex: 0 0 40%
}
.jd__data span {
  flex: 0 0 60%;
}

.jd__data.jd__others strong {
  flex: 0 0 19%
}
.jd__data.jd__others span {
  flex: 0 0 81%
}


.jd__data.jd__skills strong {
  flex: 0 0 28.5%;
}
.jd__data.jd__skills span {
  flex: 0 0 71.5%;
}
.jd__doc-preview__heading {
  font-size: 22px;
  margin: 0;
}
.separator__tq {
  margin-top: 22px;
  border-top: 3px dashed;
  padding-top: 22px;
  border-color: var(--gray-300);
}
.jd__preview-wrapper .jd__data {
  margin-bottom: 20px;
}

.resume__upload {
  position: relative;
  padding: 40px 30px;
  /* border: 1px dashed var(--third);
    border-radius: 20px; */
  margin-top: 30px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='21' ry='21' stroke='%237D878FFF' stroke-width='2' stroke-dasharray='8%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
}
.browser__Btn {
  position: relative;
  overflow: hidden;
}
.browser__Btn label {
  padding: 6px 40px;
  border: 1px solid;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  border-color: var(--main-color);
  background: var(--main-color);
}
.browser__Btn label:hover {
  border-color: var(--second-color-orange);
  background: var(--second-color-orange);
  box-shadow: var(--btn-shadow);
  color: var(--fff);
}
.browser__Btn [type="file"] {
  opacity: 0;
  display: inline-block;
  position: absolute;
  left: 0;
  z-index: -1;
}
.buttons__Group {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  max-width: 60%;
  margin: 0 auto;
}

.upload__instruction h3 {
  font-size: 18px;
  color: var(--secondary);
  margin-bottom: 6px;
}
.upload__instruction p {
  font-size: 13px;
  color: var(--third);
  margin-bottom: 0;
  font-weight: var(--l);
}
.upload__instruction {
  text-align: center;
  margin-top: 30px;
}
.Assignments-Page .fileUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--txt-color);
}
.Assignments-Page .fileUpload i {
  font-size: 28px;
  margin-right: 5px;
}
.Comments-Page .pageHeadings {
  font-size: 20px;
}
/* .Comments-Page, */
.Analytics-Page {
  max-width: 95%;
}
.heading__Jd-uploaded .float__right {
  position: absolute;
  top: 0;
  right: 10px;
}
.heading__Jd-uploaded {
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #e2e2e2;
  border-radius: 14px;
  padding: 5px 8px 5px 18px;
}
/* -----------Browse Candidate ----------- */

.candidate__tabs-section {
  padding: 30px 0;
  position: relative;
}
.candidate__tabs-section .filter__wrapper {
  position: absolute;
  top: 35px;
  right: 6px;
  display: flex;
  width: 350px;
  justify-content: space-between;
}
.candidate__tabs-section 
.w-510
{
  width: 510px;
}
button.btn.btn-success.downloadDoc {
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 99;
}
.interview__details-page .nav-link,
.candidate__tabs-section .nav-link {
  color: var(--txt-color);
  margin-bottom: 5px;
  border-radius: 12px 12px 0 0;
}
.candidate__tab-list a.nav-link {
  border-radius: var(--border-radius);
  padding: 15px;
  border: 1px solid;
  border-color: var(--card-footer);
  cursor: pointer;
  display: block;
}
/* .interview__details-page .nav-link.active,
.candidate__tabs-section .nav-link.active {
  background-color: var(--main-color);
  border-color: var(--main-color);
} */
.interview__details-page .nav-link.active,
.candidate__tabs-section .nav-link.active {
  background-color: var(--txt-color);
  border-color: var(--txt-color);
  color: #fff;
}
/* 
.candidate__tabs-section .nav-link.active .nav-link.active {
  background-color: var(--main-color);
} */
.tab__content-header {
  background-color: var(--gray-300);
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;
}

.tab__content-header .nav-link {
  margin-bottom: 0;
}
.candidate__tab-list .nav-link.active {
  background-color: var(--card-footer);
  border-color: var(--card-footer);
}
.recomended__tab .candidate__tab-list {
  width: 100%;
}
.candidate__tab-list {
  margin-bottom: 10px;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  border-color: var(--card-footer);
}
.select__job-type {
  padding: 20px;
  background-color: #ededed;
  border-radius: var(--border-radius);
  font-size: 18px;
  font-weight: 600;
  color: var(--txt-color);
  display: flex;
  justify-content: space-around;
}
.contract__select {
  background-color: aliceblue;
  padding: 15px;
  border-radius: var(--border-radius);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  max-width: 60%;
  margin: 0 auto;
  transition: 0.2s all;
}
.filter__structure .searchWrapper {
  border: none;
  border-radius: unset;
  padding: unset;
  max-height: 42px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}
.candidate__tab-list:last-child {
  border: none;
}
.card__details-tab,
.candidate__details-tab {
  font-size: 13px;
  margin: 4px 0;
  color: var(--txt-color);
}
.candidate__details-tab span {
  word-break: break-all;
}
.candidate__name {
  margin-bottom: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--txt-color);
}
.candidate__name i,
.download__resume-button i {
  font-size: 20px;
  margin-right: 5px;
}

.candidate__name .title {
  font-size: 16px;
  font-weight: 700;
}
.candidate__status-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.candidate__status-list .candidate__status {
  margin-left: auto;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  background: var(--main-color);
  padding: 2px 8px;
  font-weight: 500;
  color: var(--txt-color);
}
.download__resume-button {
  position: absolute;
  right: 18px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 6px;
  background: #198754;
  color: var(--main-bg);
  font-size: 12px;
  cursor: pointer;
}
/* ---------- CandidateSummary-Page ---------- */
.CandidateSummary-Page {
  padding: 5px;
}

.CandidateSummary-Page .working__in {
  justify-content: flex-start;
  font-size: 15px;
  text-transform: capitalize;
}
.CandidateSummary-Page .contact__details-candidate .contact__details i {
  margin-right: 5px;
  font-size: 18px;
}
.CandidateSummary-Page .candidate__designation {
  display: block;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.candidate__details-professional .jd__data.jd__skills strong {
  flex: 0 0 24%;
}
.candidate__details-professional .jd__data.jd__skills span {
  flex: 0 0 76%;
}
.getProgress__values {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.Job__type_select .progress .progress-bar {
  background-color: var(--main-color);
  border-radius: var(--border-radius);
}
.document__details .form-check {
  display: flex;
  justify-content: center;
  align-items: center;
}
.document__details .form-check .form-check-label {
  cursor: pointer;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 5px;
  color: var(--txt-color) !important;
}
.document__details .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}
.job__type p {
  background-color: transparent;
  padding: 2px 8px;
  margin-top: 8px !important;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.job__type p.true {
  background-color: var(--main-color);
}
.document__details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--card-footer);
  padding: 4px 0;
  border-radius: var(--border-radius);
}
.skills__qualification-page .label__design-analyticsdata .form-label {
  font-weight: 600;
  color: var(--txt-white);
  background-color: #5f6b75;
  display: block;
  padding: 8px 30px;
  border-radius: var(--border-radius);
}
.skills__qualification-page .dnr.form-check-input:checked {
  background-color: var(--main-color-red);
  border-color: var(--main-color-red);
}
.skills__qualification-page .suc.form-check-input:checked {
  background-color: var(--main-color-green);
  border-color: var(--main-color-green);
}
.skills__qualification-page .form-check-input:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
}
.parameters__display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.parameters__display .rscore {
  background-color: #6582af;
}
.parameters__display .ctc {
  background-color: #38a934;
}
.parameters__display .skill {
  background-color: #ddb846;
}
.parameters__display .location {
  background-color: #00a1e5;
}
.analytics__label {
  font-size: 20px;
  color: var(--txt-color);
  font-weight: 700;
}
.parameters__display .para__wrapper small {
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-color);
}
.parameters__display .para__wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
}
.parameters__display .parameterName {
  width: 20px;
  height: 20px;
  display: inline-block;
  /* background: aliceblue; */
  margin-right: 10px;
  border-radius: 5px;
}
.customize__text-field .css-1pahdxg-control {
  box-shadow: 0 0 2px 2px var(--main-color);
  border-radius: 14px;
  min-height: 45px;
}
.customize__text-field .css-1pahdxg-control:hover {
  border-color: var(--main-color);
}
.customize__text-field .css-1s2u09g-control {
  min-height: 45px;
  border-radius: 14px;
}

.was-validated .form-select:valid:not([multiple]):not([size]) {
  background-image: url(../../assets/images/arrow_down.svg);
}
.was-validated .form-control:valid {
  background-image: unset;
}
.interview__rounds-wrapper {
  padding: 10px 0 20px;
}
.interview__rounds-wrapper:not(:last-child) {
  border-bottom: 2px solid #e2e2e2;
}
.status-card__info-candidate {
  background-color: #fff;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  position: relative;
  padding: 25px 20px;
  display: flex;
  flex: 1 1 14px;
  justify-content: start;
  align-items: center;
  border-radius: 10px;
}
.status-card__info-candidate .card__Count {
  margin-right: 12px;
  font-size: 24px;
  font-weight: 800;
}
/* .cd__status strong {
  margin-right: 5px;
  flex: 0 0 32%;
}
.cd__status strong.updated__by {
  flex: 0 0 35%;
} */
/* .status__wrapper-cd {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
} */
/* .cd__status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
} */
.candidate__details-tab .badge__cd.uploadedOn {
  background: #00a2be;
  color: #fff;
}
.candidate__details-tab .badge__cd.lastUpdated {
  background: #00328f;
  color: #fff;
}
.candidate__details-tab .badge__cd {
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 5px;
  display: inline-block;
}
.candidate__details-tab .badge__cd.overdue_cd {
  height: 40px;
  width: 40px;
}
.resume__card-wrapper {
  height: 48rem;
  margin-top: 5px;
  overflow-y: scroll;
}
.comment__wrapper {
  display: block;
  padding: 15px 20px;
  background: #f2f2f2;
  margin-bottom: 10px;
  border-radius: 8px 20px 20px 25px;
  position: relative;
  overflow: hidden;
}
.comment__wrapper .comment__text {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
/* .comment__wrapper .resume__status {
  position: absolute;
  top: 14px;
  font-size: 12px;
  right: 20px;
} */
.comment__wrapper .badge {
  padding: 8px 14px;
  border-radius: 0 0 0 14px;
}
.comment__wrapper .resume__status {
  position: absolute;
  top: 0;
  font-size: 10px;
  right: -18px;
  transform: rotate(0deg) translateX(-22%);
}
.comment__wrapper .bg-Not.responding {
  display: block;
  margin-right: -10px !important;
}
.comment__wrapper .bg-Responding {
  display: block;
  margin-right: -6px !important;
}
.interview_availability-wrapper {
  position: absolute;
  left: 136px;
  top: 30px;
}
.interview_availability-wrapper.sadmin {
  left: 288px;
}
.Job__type_select.card__view-tq {
  box-shadow: none;
  border-bottom: 2px dashed #e2e2e2;
}
.interview_availability-wrapper button:hover {
  background-color: #35e1ff;
  color: var(--txt-color);
}
.candidate__email-wrapper {
  padding: 5px;
  margin-bottom: 14px;

}

.candidate__email-wrapper .email__list {
  background-color: #00a2be;
  padding: 0.4em;
  margin: 0 5px;
}