@import url("https://fonts.googleapis.com/css2?family=Babylonica&display=swap");
.uniq__cards {
  padding: 20px 25px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  /* min-height: 150px; */
  text-align: center;
}
.vendor__dashboard .page-header,
.carporates__dashboard .page-header {
  font-size: 56px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 900;
  /* opacity: 0.06; */
  color: var(--txt-color);
}
.page__header-wrapp {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
small.profile__type {
  display: flex;
  align-items: center;
  /* margin-top: 6px; */
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--txt-color);
  /* position: absolute;
  top: 51%;
  transform: translateY(-50%); */
}
small.profile__type img {
  width: 60px;
  height: 60px;
  margin-right: 18px;
}
/* small.profile__type i {
  font-size: 54px;
  color: #00548f;
} */
.u__card-1 {
  background-color: #54ccff;
}
.u__card-2 {
  background-color: #ffd232;
}
.uniq__cards .card__texture {
  display: block;
  position: absolute;
  z-index: 0;
}
.uniq__cards .card__count-No {
  z-index: 1;
  position: relative;
  font-size: 82px;
  font-weight: 900;
  color: #fff;
  margin: 0;
  line-height: 1.2;
  display: block;
  font-family: var(--poppins);
}
.uniq__cards .card__title {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  z-index: 1;
  position: relative;
}
.u__card-1 .card__texture {
  left: -6.55%;
  top: -28.18%;
  transform: matrix(0.85, 0.43, -0.57, 0.88, 0, 0);
}
.u__card-2 .card__count-No,
.u__card-2 .card__title {
  color: var(--txt-color);
}
.u__card-2 .card__texture {
  right: -10.39%;
  top: -18.99%;
  transform: matrix(0.87, 0.51, -0.49, 0.86, 0, 0);
}

.tq-defaultDashboard .card__two .card__title,
.default__bucket .card__title {
  color: var(--txt-color);
  font-size: 18px;
  line-height: 1.1;
  font-weight: 600;
  text-transform: capitalize;
  opacity: 0.7;
}
.default__bucket {
  height: 100%;
  padding: 10px !important;
}
.default__bucket .status-card__info .card__Count {
  font-size: 50px;
  font-weight: 800;
  font-family: var(--poppins);
}
.tq-defaultDashboard .card__two {
  padding: 30px 30px 10px 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  position: relative;
  min-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tq-defaultDashboard .card__two.qui__card-1 {
  background-color: #9bff7a;
}
.tq-defaultDashboard .card__two.qui__card-2 {
  background-color: #f8d383;
}
.tq-defaultDashboard .card__two.qui__card-3 {
  background-color: #b7fff9;
}
.tq-defaultDashboard .card__two.qui__card-4 {
  background-color: #f6afaf;
}
.tq-defaultDashboard .card__two.qui__card-5 {
  background-color: #fdeb6b;
}
.tq-defaultDashboard .card__two.qui__card-6 {
  background-color:#7fd2f9;
}
/* .tq-defaultDashboard .card__two .card__count-No {
  font-size: 88px;
  font-weight: 900;
  position: absolute;
  left: 0px;
  top: -28px;
  z-index: 0;
  color: rgba(255, 255, 255, 0.95);
} */
.tq-defaultDashboard .card__two .card__count-No {
  font-size: 88px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: -10px;
  z-index: 0;
  color: rgba(255, 255, 255, 0.95);
  transform: translateX(-50%);
  font-family: var(--poppins);
}
/* .tq-defaultDashboard .card__two .card__title {
  font-size: 18px;
  font-weight: 700;
  z-index: 9;
  text-align: center;
} */
/* .tq-defaultDashboard .card__two .card__title {
  font-size: 18px;
  font-weight: 700;
  z-index: 9;
  text-align: center;
  margin-top: 38px;
} */
.tq-defaultDashboard .card__two .card__title {
  font-size: 16px;
  font-weight: 700;
  z-index: 9;
  text-align: center;
  margin-top: 58px;
}
.dashboard__sub-heading {
  /* font-size: 20px;
  font-weight: 700; */
  color: var(--txt-color);
}
.table__data-component {
  border: 1px solid var(--card-footer);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
}
.border-transparent {
  border: transparent !important;
}
.jod-table tr:hover {
  background-color: transparent !important;
}
.jod-table tr,
.jod-table td,
.jod-table th {
  border: 1px solid #e2e2e2;
  text-align: center;
  vertical-align: middle;
  color: var(--txt-color) !important;
  font-size: 14px;
}
.jod__tabel-component {
  border: 2px solid #e2e2e2;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
}
.jod_table-header {
  background: var(--tq-gradient);
}

.jod_table-header > tr > th {
  padding: 12px 8px;
  font-size: 14px;
}
.welcome__msg {
  font-family: "Babylonica", cursive;
  font-size: 90px;
  font-weight: 600;
}
.margin__private-Dashboard {
  margin-top: -50;
}
.welcome__wrapper .para {
  font-size: 18px;
  margin: 5px 0 10px;
}
.plot__graph small {
  position: absolute;
  left: 50%;
  top: -5px;
  transform: translateX(-50%);
}
