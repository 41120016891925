.nav-itemsTq {
  padding: 8px 1px;
}
.tq-SideNav {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  overflow-y: auto;
  max-width: 16%;
  width: 100%;
}
.seprator-title {
  display: block;
  padding: 0px 10px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #333;
}
.logoTq {
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 28px;
}

.logoTq > img {
  height: 56px;
}

.sidebar__item {
  padding: 0 2px;
}

.sidebar__item-inner {
  padding: 12px 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: color 0.3s ease 0s;
  color: var(--txt-color);
}

.sidebar__item-inner > i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.sidebar__item-inner > span {
  text-transform: capitalize;
}

.sidebar__item-inner:hover {
  color: var(--main-color);
}

#active .sidebar__item-inner {
  border-radius: var(--border-radius);
  background-image: linear-gradient(
    to right,
    var(--main-color),
    var(--second-color)
  );
  color: var(--txt-color);
  transition: 0.2s ease-in-out;
}
