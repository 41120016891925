/* ------ large device (Tabs) --------- */
@media screen and (max-width: 991px) {
}
/* ------ Medium device (Mobile) --------- */
@media screen and (max-width: 767px) {
  .filter_button.collapsible {
    margin-top: 10px;
  }
  .dashboardJdFilterList {
    justify-content: flex-start !important;
  }
  .dashboard_header .login_control {
    margin-left: 20px;
  }
  .filterRow {
    max-width: 100% !important;
    width: 100% !important;
  }
  .filterRow fieldset {
    min-width: 100% !important;
    margin-bottom: 10px !important;
  }
  .candidateSumStatus {
    top: -40px !important;
  }
  .filters_lists fieldset {
    margin-bottom: 24px;
    width: 100%;
  }

  .filterRow fieldset .dropdown1,
  .tqFilter_options .filters_lists .dropdown1 {
    width: 100% !important;
    max-width: 100%;
  }
  .filterRow {
    max-width: 100%;
  }
  .filters_lists fieldset .dropdown {
    position: relative;
    width: 100%;
    margin-top: 12px;
    max-width: 100%;
  }
  .userProfileHeader {
    /* flex: 0 0 20%; */
  }
  .process-effects-layer {
    background-image: unset;
  }
  .md-mb-30 {
    margin-bottom: 60px;
  }
  .y-middle .text-left,
  .y-middle .text-right {
    text-align: center;
  }
  .contact-section .tq-heading-wrapper {
    text-align: left;
  }
  .tqHeader .navbar-nav {
    margin: 0 auto;
  }
  .social-handles {
    max-width: 100%;
  }
  .desktop-none {
    display: none;
  }
  .mobile-none {
    display: block;
  }
  .hero-title {
    font-size: 42px;
    margin-top: 4px;
  }
  .hero-small {
    font-size: 18px;
    margin: 0;
  }
  .wyus-card .icon {
    margin-bottom: 16px;
    display: inline-block;
  }
  .wyus-card {
    flex-wrap: wrap;
    justify-content: center;
    /* text-align: center; */
  }
  .partner-thumb {
    flex: 0 0 50%;
    text-align: center;
    margin-bottom: 10px;
  }
  .footer-cta-section {
    text-align: center;
  }
  .trustedby-section .partners-wrapper {
    justify-content: center;
  }
  .cta-text {
    font-size: 18px;
    max-width: 100%;
    margin-bottom: 4px;
  }
  .tq-heading-wrapper {
    text-align: center;
  }
  .tq-heading-wrapper .top-heading,
  .cta-title {
    font-size: 16px;
    text-transform: none;
    color: var(--tq-theme);
    /* margin-bottom: 6px; */
  }
  .cta-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .hero-description {
    max-width: 100%;
    margin: 18px auto;
    margin-bottom: 8px;
  }
  .hero-section .button-group {
    margin-top: 10px;
  }
  .personal-details .login-card,
  .personal-details .forgetCard,
  .tqlogModal .register-fromWraper {
    max-width: 94%;
    border-radius: 4px;
  }
  .personal-details .captcha-wrapper {
    justify-content: unset;
  }
  .forgetPass {
    display: flex;
    align-items: center;
  }
  .forgot-text,
  .rememberMe span {
    font-size: 14px;
  }
  .tqlogModal .steperHead {
    width: 98%;
    margin-bottom: 20px;
    border-radius: 2px;
  }
  .tq-form-container .tq-form-heading {
    font-size: 26px;
  }
  .steperHead .MuiStepLabel-label.MuiStepLabel-alternativeLabel,
  .steperHead .MuiStepLabel-label.MuiStepLabel-active {
    font-size: 10px;
  }
  .tq-ctaTwo {
    flex-wrap: wrap;
    justify-content: center;
  }
}
/* ------ Medium device (Mobile) --------- */
@media screen and (max-width: 575px) {
  .contenttq {
    text-align: center;
  }
  .wyus-card {
    flex-direction: column;
  }
  .wyus-card .icon {
    margin: 0 auto;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 637px) {
  .contenttq {
    text-align: center;
  }
}
