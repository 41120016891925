@media screen and (min-width: 768px) {
  .register__success-message {
    max-width: 80% !important;
  }
}
@media screen and (max-width: 768px) {
  .hiringPartnerBnt .about-content .rs-animations .spinner {
  display: none;
  }
  .hiringPartnerBnt .about-content .dotRight .spinner.ball {
    display: none;
  }
  .hero-section
  {
    height: auto;
  }
}
@media screen and (max-width: 576px) {
  .d-xs-none,
  .hiringPartnerBnt .about-content .dotRight .spinner.ball,
  .hiringPartnerBnt .about-content .rs-animations .spinner {
  display: none !important;
  }
  .partner-thumb {
    flex: 0 0 30%;
    text-align: center;
    margin-bottom: 10px;
  }
  .partner-thumb img {
    max-width: 100%;
  }
  .tq-ctaTwo .cta-title
  {
flex: 0 0 100%;
width: 100%;
  }
}
