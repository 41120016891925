@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800;900&family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #ffd000;
  --second-color: #ffc31d;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --btn-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);

  --card-header: #fff8e8;
  --card-footer: #d5d5d5;
  --disabled-field: #e3e3e3;
  --third: #7d878f;

  --gray-300: #dee2e6;
  --main-bg-light: #ffffff;
  --reset: #a8a8a8;

  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-hold: #fb840a;
  --main-process: #9c10d4;
  --main-schedule: #33ccff;
  --main-offered: #10d489;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #00db56;
  --second-color-green: #4fbe7c;
  --poppins: "Poppins", sans-serif;

  --main-color-orange: #faba0a;
  --second-color-orange: #fca11a;

  --main-color-olive: #679903;
  --second-color-olive: #52682e;

  /* --sidebar-width: 330px; */
  --border-radius: 15px;
  --button-radius: 10px;
  --topnav-height: 90px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: "Raleway", sans-serif;
}
.opacity-0
{
  opacity: 0 !important;
}
.shadow-0
{
  box-shadow: unset !important;
}
.shadow-1
{
  box-shadow: var(--box-shadow) !important;
}
.bg-transparent
{
  background-color: transparent !important;
}
[bg="talenQ"] {
  background-color: #fbfbfb;
}
.t-capital
{
  text-transform: capitalize !important;
}
.f-500
{
  font-weight: 500 !important;
}
.f-600
{
  font-weight: 600 !important;
}
.f-700
{
  font-weight: 700 !important;
}
a {
  color: unset;
}
.cr-auto
{
  cursor: auto !important;
}
.cr-pointer
{
  cursor: pointer !important;
}
.talenq-bkt {
  background-color: rgb(255, 215, 0) !important;
}
.text-pop {
  font-family: var(--poppins) !important;
}
.link {
  color: #0260b8;
}
.go__back i {
  font-size: 24px;
  padding: 12px 18px;
  margin-bottom: 10px;
  background: var(--card-footer);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.ml-auto {
  margin-left: auto !important;
}
.red-bkt {
  background-color: rgb(255, 208, 208) !important;
}
.green-bkt {
  background-color: rgb(208, 255, 243) !important;
}
.red-one-bkt {
  background-color: rgb(255, 221, 208) !important;
}
.white-bkt {
  background-color: rgb(255, 255, 255) !important;
}
.txt-color {
  color: var(--txt-color);
}
.w-80 {
  width: 80% !important;
}
.button__radius {
  border-radius: var(--button-radius) !important;
}
.btn:hover {
  box-shadow: var(--box-shadow);
}
.yellow {
  background-color: #fae80a !important;
  color: var(--txt-color);
}
.olive {
  background-color: var(--main-color-olive) !important;
  color: var(--txt-white);
}
.red {
  background-color: var(--main-color-red) !important;
  color: var(--txt-white);
}
.green {
  background-color: var(--main-color-green) !important;
  color: var(--txt-white);
}
.reset {
  background-color: var(--reset) !important;
  color: var(--txt-white);
}
.blue {
  background-color: var(--main-color-blue) !important;
  color: var(--txt-white);
}
.flex__vertical-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
body {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}
.position-relative {
  position: relative !important;
}
.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
[bg="talenQ"] p {
  margin-bottom: unset;
}
a {
  text-decoration: none;
  color: unset;
}
.btn:hover {
  background-color: unset;
  border-color: unset;
  color: unset;
}
.btn-primary {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  color: var(--txt-color);
}

.btn-success {
  color: var(--txt-white);
}
.btn-schedule {
  background-color: var(--main-schedule);
  border-color: var(--main-schedule);
  color: var(--txt-white);
}
.btn-offered {
  background-color: var(--main-offered);
  border-color: var(--main-offered);
  color: var(--txt-white);
}
.btn-block {
  background-color: var(--main-color-red);
  border-color: var(--main-color-red);
  color: var(--txt-white);
}
.btn-process {
  background-color: var(--main-process);
  border-color: var(--main-process);
  color: var(--txt-white);
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--second-color);
  border-color: var(--second-color);
}
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 233, 49, 0.5);
}
a:hover {
  color: var(--main-color);
}
button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}
ul {
  list-style-type: none;
}
.tq-border .modal-content {
  border-radius: var(--border-radius) !important;
}
.progress-bar.bg-primary {
  background-color: var(--main-color);
}
input {
  border: 2px solid transparent;
  outline: 0;
}
input:focus {
  border: 1px solid var(--main-color);
}
.schadule__modal .Mui-focused fieldset {
  border-color: var(--main-color) !important;
}
.modal__fileds .form-control .MuiInputBase-input:focus {
  border: none;
}
.user__list-wrapper .btn-sm,
.table-btn {
  font-size: 11px !important;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.5px 12px !important;
}
/* -------- header ------- */
.tqHeader .navbar-nav .nav-link:hover {
  color: var(--main-color);
}
.tqHeader .navbar-nav .nav-link.tqBtn:hover {
  color: unset;
}
/* -------- -------------- */
.dashboard__sub-heading {
  font-size: 26px;
  color: var(--txt-color);
  position: relative;
  /* opacity: 0.7; */
}
.dashboard__sub-heading:after {
  position: absolute;
  width: calc((100% - 99%) + 72px);
  height: 5px;
  content: "";
  background: var(--main-color);
  bottom: -12px;
  left: 0;
}
/* -------- -------------- */
.form-select {
  color: var(--txt-color);
}
.form-select:focus,
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: var(--main-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 217, 13, 0.25) !important;
}
.form-check-input:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
}
.form-check-input:focus {
  /* background-color: var(--main-color); */
  border-color: var(--main-color);
  box-shadow: 0 0 0 0.25rem rgba(253, 217, 13, 0.25) !important;
}
.page-header {
  font-size: 26px;
  margin: 0;
  text-transform: capitalize;
  font-weight: 700;
}
.tq__panel {
  background: var(--main-bg);
  box-shadow: var(--box-shadow);
  padding: 30px;
  border-radius: var(--border-radius);
}
h3 {
  font-weight: 600;
}
.card {
  padding: 20px;
  margin-bottom: 30px;
  height: 100%;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.form-control__mui {
  border-radius: var(--border-radius) !important;
  height: 45px;
}
.tq-form .form-select,
.tq-form .form-control {
  padding: 10px 14px;
  border-radius: var(--border-radius);
}
.makeStyles-search-83 {
  margin: 10px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.tq-form .form-label {
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 600;
  color: var(--txt-color);
  position: relative;
}
.tq-form .form-label .required__form {
  position: absolute;
  font-size: 25px;
  top: 64%;
  transform: translateY(-49%);
  color: #d70303;
  right: -10px;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.card__view-tq {
  background: var(--main-bg);
  box-shadow: var(--box-shadow);
  padding: 30px;
  border-radius: var(--border-radius);
}
.full-height {
  height: 100%;
}
svg [style="cursor: pointer;"],
svg g [opacity="0.4"] {
  display: none !important;
}
.card.full-height {
  height: calc(100% - 30px);
}
.jod__card {
  background-color: rgb(181, 232, 255);
  display: flex;
  justify-content: space-between;
}
.card__header {
  text-transform: capitalize;
}
.card__header h3 {
  font-size: 24px;
  font-weight: 700;
}
.card > div ~ div {
  margin-top: 5px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}
.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}
.whatsapp-active
{
  background: #00db56 !important;
  color: #fff !important;
}
.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}
.tq__page-header {
  margin-bottom: 40px;
}
.tq__page-header,
.tq__page-header .topnav__right-user__image {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topnav__right-user__image i {
  font-size: 30px;
}
.pageHeadings {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: var(--disabled-field);
  opacity: 0.6;
  color: var(--txt-color);
}
.tq-bucketTwo {
  background-color: var(--main-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
}
.tq__default-text {
  font-size: 18px;
  font-weight: 600;
}
.red-text {
  color: #ff1010;
}
.btn.btn-secondary a:hover,
.btn.btn-secondary a {
  color: unset !important;
}
.tab-scroll-y {
  height: 620px;
  overflow-y: scroll;
  padding-right: 5px;
}
.tq-SideNav::-webkit-scrollbar,
.tab-scroll-y::-webkit-scrollbar {
  width: 4px;
}

.tq-SideNav::-webkit-scrollbar-track,
.tab-scroll-y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.tq-SideNav::-webkit-scrollbar-thumb,
.tab-scroll-y::-webkit-scrollbar-thumb {
  background-color: rgb(97, 97, 97);
  outline: 1px solid rgb(97, 97, 97);
  border-radius: var(--border-radius);
}

.tq-SideNav::-webkit-scrollbar-thumb {
  background-color: var(--reset);
  outline: 1px solid rgb(138, 138, 138);
}
.newUser__registration-successPage {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
}
