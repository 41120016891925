.badge {
  padding: 6px 11px;
  color: var(--txt-white);
  border-radius: 0px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 13px;
  box-shadow: var(--box-shadow);
  margin-bottom: 14px;
  text-transform: capitalize;
}
.btn {
  border-radius: 8px;
  padding: 6px 24px;
}
.bg-Rejected,
.bg-Close,
.badge-Close {
  background-color: var(--main-color-red) !important;
  color: #fff !important;
}
.badge-Open {
  background-color: var(--main-color-green) !important;
  color: #fff !important;
}
.badge-Awaiting {
  background-color: var(--main-color-cyan) !important;
  color: #fff;
}
.bg-Inactive,
.badge-Inactive {
  background-color: var(--main-color-blue) !important;
}
.bg-Pending,
.badge-Pending {
  background-color: var(--main-color-orange) !important;
  color: var(--txt-white) !important;
}
.bg-Draft,
.badge-Draft {
  background-color: var(--main-color-olive) !important;
}
.bg-Onhold,
.badge-Onhold {
  background-color: var(--main-hold) !important;
  color: var(--txt-white) !important;
}
.bg-Scheduled,
.badge-Scheduled {
  background-color: var(--main-schedule) !important;
  color: var(--txt-white) !important;
}
.bg-OfferRevoked,
.bg-OfferRevoked {
  background-color: #0d6efd !important;
  color: var(--txt-white) !important;
}
.joday,
.JobCard .cardFooter .jobType p {
  padding: 6px 10px;
  background-color: var(--main-color-blue);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
}
.joday {
  color: var(--txt-white);
  background-color: #344dff;
}
[jodview="true"] {
  display: block;
}
[jodview="false"] {
  display: none;
}
.JobCard .cardFooter .jobType p {
  background-color: var(--main-color);
}
.filter__btn {
  color: var(--txt-color);
  font-weight: 600;
  cursor: pointer;
}
.filter__btn i {
  margin-right: 5px;
  font-size: 22px;
}
.modal__filter-wrapper {
  max-width: 80%;
}
.btn {
  font-size: 12px;
  margin: 5px;
  font-weight: 600;
}
.dropdown-toggle.download__dropdown {
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.modal__filter-wrapper .modal-title,
.schadule__modal .modal-title {
  font-weight: 700;
  letter-spacing: 1px;
}
.tq__modal .modal-content,
.modal__filter-wrapper .modal-content {
  border-radius: var(--border-radius);
  padding: 30px;
}

.modal__filter-wrapper .filter__points .filter__title {
  font-size: 20px;
  color: var(--txt-color);
}
.modal__filter-wrapper .filter__points .filter__list {
  margin-bottom: 3px;
  font-size: 15px;
  padding: 2px 5px;
  font-weight: 500;
  color: var(--txt-color);
}

.modal__fileds .form-control:focus,
.modal__fileds .form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 217, 13, 0.25) !important;
  border-color: var(--main-color);
  outline: 0;
}
.modal__fileds .form-control,
.modal__fileds .form-select {
  border-radius: var(--border-radius);
  padding: 14px;
}
.modal__fileds .form-label {
  font-size: 12px;
  font-weight: 600;
}
.tq__modal .modal-title {
  font-weight: 700;
}
.tq__modal,
.schadule__modal {
  max-width: 60%;
}
/* status badge */
.bg-Proposed {
  background-color: var(--main-offered) !important;
  color: var(--txt-white) !important;
}
.bg-Selected,
.bg-InProcess,
.profile__status .bg-InProcess {
  background-color: #008d23 !important;
  color: var(--txt-white) !important;
}
.bg-OfferAccepted,
.profile__status .bg-OfferAccepted {
  background-color: #0d6efd !important;
  color: var(--txt-white) !important;
}
.bg-Joined,
.bg-Offered,
.profile__status .bg-Offered {
  background-color: var(--main-offered) !important;
  color: var(--txt-white) !important;
}
.bg-Responding,
.bg-Screened,
.profile__status .bg-Screened {
  background-color: var(--main-process) !important;
  color: var(--txt-white) !important;
}
.bg-Not,
.bg-Declined,
.bg-Dropped,
.bg-OfferRejected,
.profile__status .bg-OfferRejected {
  background-color: var(--main-color-red) !important;
  color: var(--txt-white) !important;
}
.job__type p.Executive,
.job__type p.Walkin,
.JobCard .cardFooter .jobType p.jt-Permanent,
.Permanent {
  background-color: #00adff !important;
  color: #fff;
  border-radius: 0 10px 10px 0;
  position: relative;
}
.badge-4,
.job__type p.Executive{
  background-color: #f17016 !important;
  color: #fff;
}
.job__type p.Walkin
{
  background-color: var(--main-color) !important;
  color: var(--txt-color) ;
}
.job__type p.Executive::before,
.job__type p.Walkin::before,
.JobCard .cardFooter .jobType p.jt-Permanent::before,
.Permanent:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background: #fff;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.modal__heading {
  font-size: 20px;
  text-transform: capitalize;
  color: var(--txt-color);
}
.tq__modal-default {
  max-width: 50%;
}
.tq__modal-default.w-70 {
  max-width: 70%;
}
.tq__modal-default .modal-content {
  border-radius: var(--border-radius);
  padding: 30px;
}
.tq__modal-default .text__style {
  font-size: 16px;
  font-weight: 500;
  color: var(--txt-color);
}
.tq__modal-default .modal-title {
  font-size: 24px;
  font-weight: 700;
  color: var(--txt-color);
  text-transform: capitalize;
}
.add-co__owner .table__data {
  width: 100%;
  overflow: scroll;
  height: 320px;
}
.calenderViewPoints__calender .badge_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.calenderViewPoints__calender {
  background-color: #eee;
  padding: 30px;
  border-radius: var(--border-radius);
}
.calenderViewPoints__calender strong {
  font-size: 22px;
}
.view__all-btn {
  font-size: 18px;
  font-weight: 600;
  color: var(--txt-color);
}
.view__all-btn:hover {
  color: var(--main-color);
}
.tq-form .react-tel-input .form-control:focus {
  border-color: var(--main-color);
  box-shadow: 0 0 0 1px var(--main-color);
}
.tq-form .react-tel-input .form-control {
  padding: 10px 14px 10px 58px;
  border-radius: 14px;
  width: 100%;
}
.tq-form .react-tel-input .special-label {
  display: none;
}
.corporate__awaiting {
  background-color: #aae3fd;
}
.vendor_decline {
  background-color: #ffb0b0;
}
.round__stat.default {
  background-color: #b0ffe5;
}
.round__stat {
  padding: 2px 5px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 15px;
}
.nText {
  cursor: text !important;
}
