/* .tqBtn {
  background: var(--tq-gradient);
  margin: 0 20px;
  padding: 8px 24px;
  border-radius: 8px;
} */
/* ------------- */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
body {
  font-family: "Open Sans", sans-serif !important;
}
:root {
  --tq-gradient: linear-gradient(89.48deg, #ffbe15 -10.09%, #ffd700 97.57%);
  --tq-theme: #ffbb00;
  --tq-cl-ee: #eeeeee;
  --tq-cl-f9: #f9f9f9;
  --tq-cl-33: #333333;
  --one: #fe7800;
  --two: #009bff;
  --three: #ffcc00;
  --tq-cl-0_5: rgba(0, 0, 0, 0.5);
  --tq-cl-f_6: rgba(255, 255, 255, 0.6);
  --tq-cl-f_1: rgba(255, 255, 255, 1);
  /* --tq-font_1: "Ubuntu", sans-serif; */
  /* --tq-font: "Rubik", sans-serif; */
  --tq-font: "Poppins", sans-serif;
  --tq-fn-l: 300;
  --tq-fn-r: 400;
  --tq-fn-m: 500;
  --tq-fn-sb: 600;
  --tq-fn-b: 700;
  --tq-fn-eb: 800;
  /* --tq-fn-bl: 900; */
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}
a:hover {
  color: unset;
}
a {
  text-decoration: none !important;
  outline: none;
  color: var(--tq-theme);
}
.text-f6 {
  color: var(--tq-cl-f_6) !important;
}
.bg-f9 {
  background-color: var(--tq-cl-f9) !important;
}
.one {
  background-color: var(--one) !important;
}
.two {
  background-color: var(--two) !important;
}
.three {
  background-color: var(--three) !important;
}
.tq-heading-wrapper {
  margin-bottom: 20px;
}
.tq-heading-wrapper .top-heading,
.cta-title {
  font-size: 18px;
  color: var(--tq-theme);
  font-weight: var(--tq-fn-sb);
}
.tq-heading-wrapper .top-heading {
  margin: 0 !important;
  text-transform: uppercase;
}
.tq-heading-wrapper .section-heading {
  font-size: 32px;
  color: var(--tq-cl-33);
  font-weight: var(--tq-fn-eb);
  margin: 4px 0 10px;
  font-family: var(--tq-font);
}
.filterRowButtons {
  text-transform: unset !important;
  letter-spacing: 1px !important;
}
.tq-section-padding {
  padding: 60px 0;
}
.downloadResumeButton span {
  color: #0255d7 !important;
  font-weight: var(--tq-fn-sb);
}
.downloadResumeButton svg {
  fill: #0255d7 !important;
}
.btn-wt {
  background-color: var(--tq-cl-f_1) !important;
  color: #333333 !important;
}
.workwithus-section .serviceCard:hover img,
.tq-card:hover .tq-card-thumb {
  animation-name: dance_hover;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes dance_hover {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
.homeBenifit-section .serviceListHome img,
.workwithus-section .serviceCard img {
  width: 80px;
  padding: 8px 0px 0px 0px;
  margin-bottom: 18px;
}
.homeBenifit-section .serviceListHome,
.workwithus-section .serviceCard {
  background: #ffffff;
  box-shadow: 0px 0px 35px 4px #eee;
  padding: 40px 30px 40px 30px;
  border-radius: 5px 5px 5px 5px;
  margin: 0px -7px 0 0;
  position: relative;
  height: 100%;
}
.homeBenifit-section .serviceListHome h3,
.workwithus-section .serviceCard h3 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 10px;
}
.homeBenifit-section .serviceListHome p,
.workwithus-section .serviceCard p,
.homeHireP {
  font-size: 15px;
  line-height: 1.8;
  margin-bottom: 14px;
  opacity: 0.7;
}
.btn-theme {
  background-color: var(--tq-theme) !important;
}
.tqBtn {
  background-image: var(--tq-gradient);
  margin: 0 20px;
  padding: 8px 24px;
  z-index: 9;
}
.btn-lt {
  background-color: var(--tq-cl-ee) !important;
}
.btn-tq {
  padding: 10px 32px;
  color: #fff;
  margin: 10px 10px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out;
  background-color: 0.15s ease-in-out;
  border-color: 0.15s ease-in-out;
  box-shadow: 0.15s ease-in-out;
  box-shadow: 0 2px 2px 0 rgba(193, 136, 0, 0.14),
    0 3px 1px -2px rgba(193, 127, 0, 0.2), 0 1px 5px 0 rgba(193, 127, 0, 0.12);
}
/*----- header -----*/

.tqHeader .navbar-nav .nav-link {
  padding: 10px 20px;
  display: inline-block;
}
.tqHeader .navbar-nav {
  margin-left: auto;
}
/*-------hero section ------*/
.hero-section {
  height: 100vh;
  background-image: url("https://talenq.r.worldssl.net/talenq/img/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-small {
  font-size: 26px;
  color: var(--tq-theme);
  font-weight: var(--tq-fn-m);
}
.hero-description {
  max-width: 80%;
  margin: 18px auto;
  color: var(--tq-theme);
  font-weight: var(--tq-fn-r);
  font-size: 18px;
  line-height: 2;
}
.hero-title {
  font-size: 72px;
  color: var(--tq-cl-33);
  font-weight: var(--tq-fn-eb);
  margin-bottom: 30px;
  margin-top: 10px;
}
.employers-page .hero-title {
  font-size: 58px;
}
.hero-section .button-group {
  margin-top: 40px;
}
/*--------- emp-banner section ---------- */

.employers-page .employer-banner {
  height: 100vh;
  background-image: url("https://talenq.r.worldssl.net/talenq/img/employers/empBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hiringPart-page .hiring-banner,
.about-banner {
  height: 100vh;
  background-image: url("https://talenq.r.worldssl.net/talenq/img/hiringPartner/hireBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-banner {
  background-image: url("https://talenq.r.worldssl.net/talenq/img/aboutus/aboutBanner.png");
  background-position: center center;
}
.visionMission-section .desc-text {
  font-size: 15px;
  color: var(--tq-cl-33);
  opacity: 0.7;
}
.mission-thub {
  border-radius: 20px;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.2);
}
.hiring-banner .hero-title {
  font-size: 52px;
}
.sec-title .sub-text {
  font-size: 15px;
  line-height: 28px;
  font-weight: 600;
  color: #191821;
  margin: 0 0 10px;
  text-transform: capitalize;
  display: block;
}
.sec-title .sub-text.small {
  font-size: 15px;
  color: var(--tq-theme);
  text-transform: uppercase;
}
.sec-title .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #0a0a0a;
  margin: 0;
  position: relative;
}
.pb-25 {
  padding-bottom: 25px;
}
.hiringPartnerBnt .about-content {
  position: relative;
  z-index: 111;
}
.hiringPartnerBnt .about-content .rs-animations .spinner.ball {
  bottom: -66px;
  left: -73px;
}
.hiringPartnerBnt .about-content .rs-animations .spinner {
  position: absolute;
  bottom: 0;
  z-index: -11;
}
.hiringPartnerBnt .about-content .rs-animations .spinner.dot {
  top: -45px;
  left: -40px;
}
.hiringPartnerBnt .scale {
  animation: scale 2s alternate infinite;
}
.dance2 {
  animation: dance2 4s alternate infinite;
  -webkit-animation: dance2 4s alternate infinite;
}
.hiringPartnerBnt .about-content .dotRight .spinner.dot {
  top: -45px;
  right: -40px;
  left: auto;
}
.hiringPartnerBnt .about-content .dotRight .spinner.ball {
  bottom: -45px;
  right: -40px;
  left: auto;
}
.EmpPartnerBnt {
  padding-top: 0;
}
.hiringPartnerBnt {
  padding-top: 140px;
}
@keyframes scale {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
  }
}
.hiringPartnerBnt .accordion-item {
  background-color: transparent;
  border: none;
}

.hiringPartnerBnt .accordion-item [aria-expanded="true"]::after {
  transform: rotate(90deg);
}
.hiringPartnerBnt .accordion-item [aria-expanded="true"] {
  background: transparent;
  color: #333;
  border-radius: 8px;
}
.hiringPartnerBnt .accordion-item .accordion-header {
  position: relative;
}
.hiringPartnerBnt .accordion-item .accordion-header button {
  box-shadow: none;
  background: transparent;
}
.hiringPartnerBnt .accordion-item .accordion-header button::after {
  background-image: unset;
  content: "›";
  font-size: 28px;
}
.hiringPartnerBnt .accordion-item .accordion-header button::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: gold;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
}
.hiringPartnerBnt .accordion-item .accordion-button:focus {
  border-color: #fee086;
  box-shadow: none;
}
.hiringPartnerBnt .accordion-item .accordion-body {
  background: #fbfbfb;
  margin-top: 8px;
  border: 1px dotted #e2e2e2;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.8;
  opacity: 0.7;
}
/*--------- Counrt section ---------- */
/*--------- Counrt section ---------- */
.count-card {
  position: relative;
  padding: 10px;
  text-align: center;
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column; */
}
.numbers {
  font-size: 52px;
  font-weight: var(--tq-fn-eb);
  color: var(--tq-theme);
  display: block;
  margin-bottom: 8px;
}
.count-title {
  font-size: 18px;
  margin-top: 4px;
  color: var(--tq-cl-33);
  font-weight: var(--tq-fn-m);
}
.count-card img {
  max-width: 60px;
  display: block;
  margin: 0 auto;
}
/*-------- cta strip -------*/
.cta-strip-section {
  background: var(--tq-gradient);
}
.cta-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
}
.cta-text {
  font-size: 18px;
  max-width: 60%;
  color: var(--tq-cl-f_1);
}
/*---- trusted section -----*/
.trustedby-section .partners-wrapper {
  display: flex;
  padding: 10px 0;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.partner-thumb {
  flex: 0 0 14.1%;
  text-align: center;
}
.partner-thumb img {
  max-width: 70%;
}
/*----- flow start ------*/
.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.process-effects-layer {
  background-image: url(https://talenq.r.worldssl.net/talenq/img/steps.png);
  background-position: center 60%;
  background-repeat: no-repeat;
  background-size: 880px auto;
  margin-top: -140px;
  margin-bottom: 0px;
  padding: 160px 0px 0px 0px;
}
.talenq-flow {
  background-image: url(https://talenq.r.worldssl.net/talenq/img/stepsTexture.png);
  background-repeat: no-repeat;
  background-position: 70%;
}
.process-effects-layer .tq-addon-number .number-part .number-image {
  background: #fff;
  padding: 50px;
  border-radius: 50%;
  display: flex;
  width: 140px;
  height: 140px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.1);
}
.process-effects-layer .tq-addon-number .number-part .number-image img {
  width: 60px;
  display: block;
}
.process-effects-layer .tq-addon-number .number-part .number-text {
  position: relative;
  text-align: center;
}
.process-effects-layer .tq-addon-number .number-part .number-text .number-area {
  position: relative;
  transition: all 0.3s ease 0s;
  text-align: center;
  width: 50px;
  height: 50px;
  left: -50px;
  top: -36px;
  margin: -13px 0px 0px 0px;
  display: inline-block;
  border-radius: 50px 50px 50px 50px;
  background: var(--tq-theme);
}
.process-effects-layer
  .tq-addon-number
  .number-part
  .number-text
  .number-area
  .number-prefix {
  font-size: 18px;
  line-height: 50px;
  font-weight: 700;
  color: #ffffff;
}
.process-effects-layer
  .tq-addon-number
  .number-part
  .number-text
  .number-title
  .title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #333;
  margin: 0 0 10px;
}
.knowMoreUser {
  background: var(--tq-cl-0_5);
  display: inline-block;
  padding: 10px 18px;
  margin-top: 10px;
  color: #fff;
}
.knowMoreUser:hover {
  background: var(--tq-theme);
}
/*----- Flow Process end  ------*/
/*----- why choose us ------*/
.whyus-wrap {
  margin-top: 30px;
}
.contenttq .card-title {
  font-size: 22px;
  font-weight: var(--tq-fn-b);
  opacity: 0.8;
}
.card-text {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--tq-cl-0_5);
}
.wyus-card .icon {
  max-width: 84px;
  height: 84px;
  display: flex;
  padding: 15px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}
.wyus-card .icon img {
  max-width: 98%;
  margin: 0 auto;
}
.wyus-card {
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.contenttq .read-more {
  font-size: 16px;
  font-weight: var(--tq-fn-sb);
}
.contenttq {
  flex: 0 0 80%;
  margin-left: 30px;
}
.contact-section .tq-heading-wrapper .top-heading {
  font-size: 20px;
  font-weight: var(--tq-fn-sb);
}
.contact-section .tq-heading-wrapper .section-heading {
  font-size: 22px;
  margin: 4px 0 10px !important;
  font-weight: var(--tq-fn-b);
  line-height: 1.5;
}
.tq-ctaTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*------------- footer ---------------*/
.footer-bottom {
  background-color: rgba(0, 0, 0, 1);
  padding: 20px 0;
}
.footer-top .form-group .btn-tq.tqBtn {
  padding: 8px 20px;
  font-size: 15px;
}
.footer-top img {
  max-width: 160px;
}
.footer-top .btn-tq {
  padding: 8px 18px;
  font-size: 16px;
  margin: 10px 6px;
  border-radius: 6px;
}
.footer-top .form-control {
  border-radius: 10px;
  background: transparent;
}
.form-control:focus {
  color: var(--tq-cl-33);
  border-color: #ffd450;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.25);
}
.footer-text {
  color: var(--tq-cl-f_6);
  font-weight: var(--tq-fn-l);
  font-size: 12px;
}
.footer-top {
  background: #090909;
}
.footer-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--tq-cl-f_1);
}
.footer-link {
  display: block;
  margin: 8px auto;
  color: var(--tq-cl-f_6);
  font-size: 14px;
  font-weight: var(--tq-fn-l);
}
.footer-link:hover {
  color: var(--tq-theme);
}
/*---- Footer CTA ----*/
.footer-cta-section {
  background-image: url("https://talenq.r.worldssl.net/talenq/img/texture.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.footer-cta-section .cta-text {
  color: var(--tq-cl-0_5);
  max-width: 100%;
  margin: 10px 0 20px;
}
/* ------ testi monials -----*/
.testimonial-section {
  background: url("https://talenq.r.worldssl.net/talenq/img/testimonials/testimonial_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.testcontent-wrap {
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 100px 40px 30px;
  border-radius: 30px;
  top: 40px;
  right: -50px;
  z-index: 99;
}
.testcontent-wrap::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  z-index: -1;
  filter: blur(2px);
}
.testimonial-section .thumbonial {
  border-radius: 20px;
}
.testimonial-section .profile-thumb {
  display: inline-block;
  position: absolute;
  top: -100px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px 14px rgba(0, 0, 0, 0.05);
}
.testimonial-section .org-type {
  padding: 10px 20px;
  background: var(--tq-theme);
  border-radius: 10px;
  position: absolute;
  top: 25px;
  right: 30px;
}
.testimonial-section .org-person {
  margin-top: 30px;
}
.testimonial-section .org-person .pro-name {
  font-size: 30px;
  font-weight: var(--tq-fn-m);
}
.testimonial-section .org-person small {
  color: var(--tq-cl-0_5);
  font-size: 14px;
  font-weight: var(--tq-fn-l);
}
.testimonial-section .tq-heading-wrapper {
  margin-bottom: 28px;
}
.wrap-carousal .owl-theme .owl-dots .owl-dot.active span {
  background-color: rgba(255, 255, 255, 1);
}
.wrap-carousal .owl-theme .owl-dots .owl-dot span {
  width: 26px;
  height: 26px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 6px;
}
.wrap-carousal .owl-theme .owl-dots .owl-dot:hover span {
  background-color: rgba(255, 255, 255, 1);
}
.wrap-carousal .owl-nav {
  position: absolute;
  left: 40px;
  bottom: 20px;
}
.wrap-carousal .owl-theme .owl-nav [class*="owl-"] {
  background: transparent;
}
.wrap-carousal .owl-theme .owl-nav svg {
  width: 42px;
}
.hero-img {
  filter: grayscale(1);
}
.logo-stamp {
  position: absolute;
  bottom: -80px;
  display: block;
  max-width: 140px;
  left: 94px;
}
/*----------*/
.visionMission-section .quote-text mark {
  background: transparent;
  color: var(--tq-theme);
}
.visionMission-section .quote-text {
  font-size: 18px;
  padding-left: 24px;
  border-left: 4px solid var(--tq-theme);
  margin: 10px 0 24px;
}
.visionMission-section .content-wrapper {
  max-width: 88%;
}
/*--------*/
.team-section .tq-heading-wrapper,
.benifits-section .tq-heading-wrapper {
  margin-bottom: 60px;
}
.team-thumb {
  border-radius: 20px;
}
.team-card .card-body {
  padding: 10px 0;
}
.team-card .card-body .designation {
  display: block;
  margin: 4px 0 14px;
  color: var(--tq-cl-0_5);
}
.team-card .card-body .member-name {
  font-size: 20px;
  margin: 0;
  color: var(--tq-cl-33);
}
.team-section .tq-heading-wrapper .heading-desc,
.card-desc {
  font-size: 18px;
  color: var(--tq-cl-0_5);
}
.heading-desc {
  font-size: 15px;
  color: var(--tq-cl-33);
  opacity: 0.6;
  font-weight: var(--tq-fn-r);
}

.mobile-none {
  display: none;
}
/*------- benifits section ------*/
.tq-bft-card .values {
  max-width: 80px;
  margin: 0 auto;
  margin-bottom: 14px;
}
.benifits-section .tq-bft-card {
  padding: 30px;
  background: #fff;
  /* box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.05); */
  border-radius: 24px;
  position: relative;
  text-align: center;
}
.aboutus-page .footer-cta-section {
  background: #f9f9f9;
}
.tq-bft-card .numbers {
  position: absolute;
  left: 18px;
  font-size: 44px;
  top: 45px;
  opacity: 0.2;
  color: var(--tq-cl-0_5);
}
.tq-bft-card .card-title {
  font-size: 20px;
  color: var(--tq-cl-33);
}
.tq-bft-card .card-desc {
  font-size: 15px;
  color: var(--tq-cl-0_5);
  font-weight: var(--tq-fn-l);
}

/*----------- Contact Us --------*/
.services-section {
  background: url("https://talenq.r.worldssl.net/talenq/img/texture.png"), #fff;
}
.contact-section {
  background: url("https://talenq.r.worldssl.net/talenq/img/texture.png"),
    var(--tq-cl-f9);
}
.contact-form .form-group {
  margin-bottom: 32px;
}
.contact-form .form-control {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 18px 15px;
  display: inline-block;
  border-radius: 14px;
  color: var(--tq-cl-33);
  font-weight: var(--tq-fn-l);
  letter-spacing: 1px;
  margin-top: 4px !important;
}
.contact-form {
  background: rgba(255, 255, 255, 0.5);
  padding: 35px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  z-index: 99;
}
.contact-form input.form-control::placeholder {
  color: #979589 !important;
}
.contact-form::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  filter: blur(5px);
  z-index: -1;
}
.contact-details {
  padding: 10px 25px;
}
.contact-details .desc-text {
  font-size: 16px;
  font-weight: var(--tq-fn-r);
}
.contact-details .input-group-prepend {
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.social-handles .social-link {
  padding: 10px;
  /*flex: 0 0 33%;*/
}
.social-handles .desc-text {
  flex: 0 0 100%;
  margin-bottom: 10px;
}
.social-handles {
  display: flex;
  justify-content: space-between;
  max-width: 36%;
  margin: 10px 0 20px;
  flex-wrap: wrap;
}

/*--------Services Page----------*/
.number-card {
  position: relative;
  padding: 20px;
}
.number-card .numbers {
  font-size: 42px;
  display: block;
  line-height: normal;
  margin-top: 10px;
}
.number-card .count-title {
  font-size: 16px;
  margin-top: 2px;
}
.services-section .tq-card {
  position: relative;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 24px 20px;
  border-radius: 14px;
  border: 1px dotted rgba(0, 0, 0, 0.3);
  box-shadow: none;
}

.services-section .tq-card:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background: rgb(255, 247, 137);
}
/*.services-section .tq-card:hover
{
	box-shadow: 0 0 18px 0 rgba(0,0,0,0.05);
	cursor: pointer !important;	
}*/
.services-section .tq-heading-wrapper {
  margin-bottom: 0px;
}
.services-section .tq-card .card-desc {
  font-size: 14px;
  color: var(--tq-cl-33);
  font-weight: var(--tq-fn-r);
  opacity: 0.5;
}
.services-section .tq-card .card-title {
  font-size: 17px;
  margin: 8px 0 8px;
  font-weight: var(--tq-fn-sb);
}
.trustbuilding-section {
  background: url("https://talenq.r.worldssl.net/talenq/img/services/count_texture.png");
  background-repeat: no-repeat;
  background-size: cover;
}
/*---------*/
.tq-card {
  /* text-align: center; */
  position: relative;
  height: 100%;
  /* box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 18px;
}
.tq-card .card-title {
  margin: 10px 0;
  font-size: 17px;
  font-weight: var(--tq-fn-b);
}
.tq-card .card-desc {
  font-size: 14px;
  color: var(--tq-cl-33);
  font-weight: var(--tq-fn-r);
  opacity: 0.6;
}
.tq-card .tq-card-thumb {
  max-width: 60px;
  margin-bottom: 10px;
}
.videoBg-section {
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  overflow: hidden;
}
.videoBg-section .wrapper__video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.employers-page .tq-heading-wrapper,
.hiringPart-page .tq-heading-wrapper {
  text-align: center;
}
.txtField,
.jodtxtFld {
  position: relative;
}
.txtField .MuiInputBase-root,
.jodtxtFld .MuiInputBase-root {
  border-radius: 14px;
}
.txtField [data-shrink="true"],
.jodtxtFld [data-shrink="true"] {
  transform: translate(14px, -14px) scale(0.75) !important;
  background: #fff;
  padding: 4px 12px;
}
.borderBottom-seprator {
  padding-bottom: 44px;
  border-bottom: 8px solid #f2f2f2;
}
.SearchButtonSchadulePage {
  text-align: right;
  padding: 30px;
}
.calenderViewPoints {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
}
.tq-CardHeader {
  background: linear-gradient(60deg, #26c6da, #ffbb00) !important;
  color: #fff !important;
}
.tqDialogue.rejectReson [role="dialog"] {
  top: 20px;
  margin: 0;
  padding: 10px 30px;
  overflow-y: unset !important;
}
.ui.olive.candidateSumStatus {
  background: #2bc400;
  padding: 2px 8px;
  color: #fff;
}
label.Mui-focused {
  color: #008145 !important;
}
.Mui-focused fieldset {
  border: 2px solid #008145 !important;
}
.MuiFormControl-root .MuiInput-underline::after {
  border-bottom-color: #008145 !important;
}
.calenderViewPoints .olive {
  background: rgb(114, 214, 0);
}
.calenderViewPoints .teal {
  background: #03dfea;
}
.calenderViewPoints .blue {
  background-color: #0392ea;
}
.calenderViewPoints .ui {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.slotBookingMax {
  background-color: rgb(229, 247, 253);
  border-radius: 10px;
}
.maxSlotLists .maxSlots-Item {
  padding: 10px 10px 20px;
  margin-bottom: 15px;
  border-bottom: 6px solid #fff;
}
.maxSlotLists div:last-child .maxSlots-Item {
  border-bottom: none;
}
.maxSlotLists .maxSlots-Item .tqSlotRows {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: #fdf581;
  margin: 5px 0;
}
.tqSlotRows .datesAvl {
  background-color: red;
}
.tQslotsDetails strong {
  font-size: 15px;
  font-weight: var(--tq-fn-sb);
  margin-right: 4px;
}
.tQslotsDetails {
  font-size: 15px;
  padding: 4px 10px;
  background-color: #eee;
  border-radius: 4px;
  margin-right: 15px;
}
.tableRowtq .tQslotsDetails.rnm {
  background-color: rgb(250, 175, 175);
}
.tableRowtq .tQslotsDetails.mdtp {
  background-color: rgb(255, 245, 153);
}
.tQslotsDetails.rnm {
  background-color: #fee;
}
.tQslotsDetails.mdtp {
  background-color: rgb(255, 252, 223);
}
.tQslotsDetails.tpn {
  background-color: rgb(238, 248, 255);
}
.tQslotsDetails.tdt {
  background-color: rgb(238, 255, 244);
}
.boxShadowUnset {
  box-shadow: unset !important;
  margin: 10px 0 !important;
  padding: 10px 10px !important;
  border-radius: 10px !important;
}
