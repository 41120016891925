.loaderContainer {
  background-color: grey;
  width: 100%;
  height: 100vh;
  opacity: 0.9;
  position: fixed;
  z-index: 999999;
  opacity:0.7;
  top: 0;
}

#loader {
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out,
    margin 0.3s ease-in-out, transform 0.6s cubic-bezier(0, 0, 0, 1);
  transform: scale(1.2) translate3d(0, 0, 0);
}
.ball-scale-multiple {
  position: relative;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.loader_logo {
  position: relative;
  top: 11px;
  left: 11px;
  width: 62px;
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
  animation: pulsate-fwd 1s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.ball-scale-multiple {
  position: relative;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}
.ball-scale-multiple > div:nth-child(2) {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.ball-scale-multiple > div:nth-child(3) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.ball-scale-multiple > div {
  background-color: rgba(216, 211, 193, 0.26);
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  left: -8px;
  top: 21px;
  opacity: 0;
  margin: 0;
  width: 100px;
  height: 100px;
  -webkit-animation: ping 2s ease-in-out infinite both;
  -moz-animation: ping 2s ease-in-out infinite both;
  animation: ping 2s ease-in-out infinite both;
}
@-webkit-keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.loader_logo2 {
  position: absolute;
  top: 21px;
  left: 23px;
  width: 38px;
  animation: heartbeat 2.5s ease-in-out infinite both;
}
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
