.JobCard,
.undersubscribe__card {
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.undersubscribe__card {
  padding: 15px;
  background-color: #e4eaed;
}
.undersubscribe__card .cardTitle,
.JobCard .cardTitle {
  font-size: 18px;
  font-weight: 700;
  padding: 5px 10px;
  font-family: "Raleway", sans-serif;
  color: var(--txt-color);
  text-transform: capitalize;
}
.JobCard .cardPriority {
  border-bottom: 3px dashed #e2e2e2;
  padding-bottom: 8px;
  margin-bottom: 7px;
}
.JobCard .otherDetails {
  border: none;
}
.JobCard .cardHeader .chartArea {
  max-width: 140px;
  background: #fff;
  height: 120px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Find__Job-card .cardHeader .chartArea {
  max-width: 140px;
  height: 140px;
}
.undersubscribe__card .cardBody,
.JobCard .cardBody {
  padding-top: 10px;
}
.JobCard .cardFooter {
  background: var(--card-footer);
}
.JobCard .cardHeader {
  background: var(--card-header);
  padding: 24px 10px;
  cursor: pointer;
}
.undersubscribe__card .cardRow,
.JobCard .cardRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
}
.undersubscribe__card .cardRow strong,
.JobCard .cardRow strong {
  flex: 0 0 40%;
  font-size: 15px;
}
.undersubscribe__card .owl-nav svg {
  max-width: 18px;
}
.under__subscribed-jobs {
  position: relative;
}

.under__subscribed-jobs .owl-nav {
  position: absolute;
  bottom: -30px;
  right: 0;
}
.undersubscribe__card .cardRow p,
.JobCard .cardRow p {
  font-size: 14px;
  text-align: left;
  flex: 0 0 60%;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.JobCard .cardFooter {
  padding: 10px;
}
.JobCard .buttonsGroup {
  display: flex;
  justify-content: flex-end;
  /* padding: 10px 6px; */
}
.JobCard .buttonsGroup .jobAction {
  padding: 5px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
.find__jobs-page .Find__Job-card .buttonsGroup {
  justify-content: end;
}
.find__jobs-page .Find__Job-card .buttonsGroup .jobAction {
  padding: 5px 10px;
  font-size: 13px;
  width: unset;
  height: unset;
  border-radius: 6px;
  background-color: var(--main-color);
  font-weight: 500;
}
.find__jobs-page .Find__Job-card .buttonsGroup .jobAction.true {
  background-color: var(--main-color-green);
  color: #fff;
}
/* Setting Cards */
.setting-card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.5s ease 0s;
}
.setting-card__icon {
  max-width: 60px;
}
.setting-card::before {
  content: "";
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-image: linear-gradient(
    to top right,
    var(--main-color),
    var(--second-color)
  );
  position: absolute;
  left: -50%;
  top: 0;
  transform: scale(0);
  transition: transform 0.8s ease 0s;
  z-index: -1;
}
.setting-card__info h4 {
  color: var(--txt-color);
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.setting-card:hover::before {
  transform: scale(3);
}
.setting-card:hover h4 {
  color: var(--txt-white) !important;
}
.resume__meter-wrapper .chartArea {
  position: relative;
}
.hoverBadge {
  display: none;
  position: absolute;
  top: -24px;
  left: -20px;
  width: 100%;
  z-index: 999999;
}
.resume__meter-wrapper .chartArea:hover .hoverBadge {
  display: block;
}
.JobCard .special__props {
  position: absolute;
  bottom: -16px;
  width: max-content;
  left: 4px;
} 

.special__props .badge-40 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}