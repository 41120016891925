.topnav {
  /* padding: 30px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--topnav-height);
  margin-bottom: 4px;
}
.user__view .dropdown__toggle {
  position: relative;
}
.user__view .active ~ .dropdown__toggle::after {
  transform: rotate(-180deg);
}
.tqSearchFixed .MuiPaper-rounded {
  top: 15px !important;
  left: calc(100% - 82%) !important;
  box-shadow: none;
  padding: 2px 30px;
  transition: 0.2s all !important;
  border: 1px solid black;
  background: #fff;
}
.user__view .dropdown__toggle::after {
  position: absolute;
  content: "";
  background-image: url(https://talenq.r.worldssl.net/talenq/img/arrow_down.svg);
  background-size: cover;
  width: 14px;
  height: 14px;
  right: -22px;
  top: 50%;
  transform: translateY(-50%);
}
.topnav__search,
.find__job-search {
  position: relative;
  height: 50px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.find__job-search > input,
.topnav__search > input {
  height: 100%;
  width: 100%;
  padding: 10px 60px 10px 20px;
  font-size: 1rem;
  border-radius: var(--border-radius);
  color: var(--txt-color);
  background-color: var(--main-bg);
}

.find__job-search > input {
  padding-left: 50px;
}

.find__job-search > button,
.find__job-search > i {
  font-size: 1.5rem;
  position: absolute;
  left: 20px;
}
.find__job-search > button {
  font-size: 15px;
  left: unset;
  right: 0px;
  font-weight: 600;
  top: 50%;
  transform: translateY(-64%);
}
.topnav__search > i {
  font-size: 1.5rem;
  position: absolute;
  right: 20px;
}

.topnav__right {
  display: flex;
  align-items: center;
}

.topnav__right-item ~ .topnav__right-item {
  margin-left: 30px;
}

.notification-item {
  display: flex;
  align-items: center;
  /* padding: 20px; */
  padding: 20px 20px 0px;
  color: var(--txt-color);
  font-weight: 500;
}

.notification-item:hover {
  background-color: var(--second-bg);
  color: var(--main-color);
}

.notification-item > i {
  margin-right: 20px;
  font-size: 1.5rem;
}

.topnav__right-user {
  display: flex;
  align-items: center;
}

.topnav__right-user__image {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 10px;
}

.topnav__right-user__image > img {
  width: 100%;
}

.topnav__right-user__name {
  font-size: 1rem;
  font-weight: 600;
}
.notification__structure-wrap {
  position: relative;
  padding: 20px;
}
.floating__time {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
}
.user__view {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user__view .user__icons {
  max-width: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.user__view .dropdown button {
  padding: 6px;
  font-size: 16px;
}
.f-28 {
  font-size: 28px;
}
button#input-group-dropdown-2:after {
  display: none;
}
.user__view .dropdown .dropdown-menu.show {
  border: none;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  text-align: center;
  inset: 12px auto auto -30px !important;
  /* top: 12px;
  right: 30px; */
}
span.blinking__notice {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: var(--main-color);
  position: absolute;
  top: -5px;
  right: 2px;
  border-radius: 50%;
  -webkit-animation: pulse 1s cubic-bezier(1, -0.01, 0, 0.98) 1s infinite;
}
.comments_tab span.blinking__notice {
  top: -2px;
  right: -8px;
  width: 12px;
  height: 12px;
}
@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 8px #e7ea4c, inset 0 0 8px #fc1d60;
  }
  50% {
    box-shadow: 0 0 16px #ffb412, inset 0 0 14px #00aeff;
  }
  100% {
    box-shadow: 0 0 8px #764cea, inset 0 0 8px #eac04c;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 8px #e7ea4c, inset 0 0 18px #fc1d60;
  }
  50% {
    box-shadow: 0 0 22px #ffb412, inset 0 0 14px #00aeff;
  }
  100% {
    box-shadow: 0 0 8px #0ae2ff, inset 0 0 18px #eac04c;
  }
}

.new__tag .blink {
  font-size: 10px;
  text-decoration: blink;
  background: rgb(255, 232, 28);
  padding: 4px 10px;
  color: #333;
  -webkit-animation: pulse 1s cubic-bezier(1, -0.01, 0, 0.98) 1s infinite;
}
.candidate__tab-wrapper {
  position: relative;
}
.candidate__tab-wrapper .new__tag {
  position: absolute;
  right: 0;
  bottom: 0;
}
.notification-item.unseen {
  background-color: #f5fffc;
}

/* browse candidate new css  */
.tab-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 14px 14px 0 0;
}

.tab-card > span {
  margin-left: -12px;
}
.tab-card.active {
  background-color: var(--main-color);
}
